import { useEffect } from 'react';
// import communityImage from '../../../assets/img/community/community.jpg'
import communityImage from '../../../assets/img/community/community-2.jpeg';
import { why } from "../../../constants";
import AOS from "aos";
import "aos/dist/aos.css";
import { BsDash } from "react-icons/bs";

const About = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>
            <div className="container pt-10 pb-10 pt-md-14 pb-md-5 col-11">
                <div className="row gx-lg-8 gx-xl-12 gy-10 mb-5 align-items-center justify-content-center">
                    <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-6 text-center text-lg-start order-2 order-lg-0" data-group="page-title" data-delay="600">
                        <div className="col-md-10 col-lg-12 col-xl-13 col-xxl-13 mx-auto mb-7" data-group="page-title">
                            <h1 className="display-1 mb-2 mb-md-4" style={{ 'fontWeight': 700 }} data-aos="fade-down" data-aos-duration='900'
                            > About Our Community</h1>
                            <p className=" fs-lg mb-7" data-aos="fade-down"
                                data-aos-delay="800" data-aos-duration='900'>
                                Women currently hold 25% of coding jobs and earn 30% less money than men. Codevixens is here to help bridge the gender gap in the tech programming industry by providing knowledge that is becoming necessary in today’s technological job market.
                            </p>
                            <a
                                href='https://slack.codevixens.org'
                                target='_blank'
                                rel='noreferrer'
                                className='btn btn-lg btn-primary rounded-pill mb-0 text-nowrap animation-default animation-delay-1200'>
                                <i className='uil uil-slack me-2 fs-20'></i>
                                Join Our Community
                            </a>
                        </div>
                    </div>

                    <div className=" col-11 col-lg-6 animation-delay-1500" data-aos="zoom-in-left" data-aos-delay="1500" data-aos-duration='900'>
                        <figure className='rounded shadow-lg'>
                            <img className="w-auto" src={communityImage} alt="community" />
                        </figure>
                    </div>
                </div>
            </div>
            <section className="wrapper mb-10 mb-md-13">
                <div className="container py-14 py-md-10">
                    <div className="row mb-2">
                        <div className="col-lg-9 col-xl-8 col-xxl-7" data-aos="fade-down"
                            data-aos-duration="2000" data-aos-once="false">
                            <p className='text-primary'><BsDash />WHY JOIN US</p>
                            <h3 className="display-3 mb-md-5"> Why you should join our online community?</h3>
                            <p className="mb-md-5">Joining a tech community is a necessary step every developer needs to get the right positioning that will usher them into a successful career.</p>
                        </div>
                    </div>
                    <div className="row gx-md-5">
                        {
                            why.map((reason, index) => (
                                <div className="col-10 col-md-6 col-xl-4" key={index} data-aos="fade-down"
                                    data-aos-delay={`${(index + 1) * 400}`} data-aos-once="false" data-aos-duration='900'>
                                    <div className="card-body" >
                                        <div className="icon btn btn-block btn-lg pe-none mb-4 bg-soft-primary"> <i className={`text-primary uil uil-${reason.icon}`}></i> </div>
                                        <h4 className="mb-3">{reason.title}</h4>
                                        <p>{reason.description}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default About;
