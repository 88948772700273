import React from 'react';
import vesi from '../../../assets/img/home/vesicash.png';
import ngc from '../../../assets/img/home/ngc.png';
import fa from '../../../assets/img/home/fa.png';
import mal from '../../../assets/img/home/mal.png';

function OurPartners() {
    const companies = [
        "https://res.cloudinary.com/dar3e312jder/image/upload/v1697603361/website/partners%20and%20collaborators/Propel_Logo_Black_dlxrsm.png", 
        "https://res.cloudinary.com/dar3e312jder/image/upload/v1697603479/website/partners%20and%20collaborators/Infobip-logo_kc3ram.svg", 
        'https://res.cloudinary.com/dar3e312jder/image/upload/v1714061210/website/where%20our%20talents%20work/Paga_fn2iyx.svg', 
        fa,
        mal, 
        ngc,
        vesi,
        'https://res.cloudinary.com/dar3e312jder/image/upload/v1714061197/website/where%20our%20talents%20work/Flutterwave_n5sjqo.svg'
    ];

    return (
        <>
            <section id="snippet-10" className="wrapper bg-light pt-13">
                <div className="container pt-0 pb-0">
                    <div className="text-center">
                        <h2 className="display-6 mb-1">Our Partners & Collaborators</h2>
                        <p className="lead fs-18 mb-7 col-8 mx-auto">Discover our prestigious partners and collaborators who share our vision for high quality, affordable, gbobal tech education.</p>
                    </div>
                    <div className="swiper-container clients mt-5 pt-3" data-margin="30" data-reverse="true" data-dots="false" data-loop="true" data-autoplay="true" data-autoplaytime="1" data-drag="true" data-speed="9000" data-items-xxl="6" data-items-xl="6" data-items-lg="6" data-items-md="4" data-items-xs="2">
                        <div className="swiper">
                            <div className="swiper-wrapper ticker">
                                {
                                    companies.map((img, key) => (
                                        <div className="swiper-slide px-5 h-100px align-items-center d-flex" key={key}>
                                            <img src={img} alt="" />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OurPartners;
