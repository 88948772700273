import React, { useEffect } from 'react';
import ezengwa from '../../../assets/img/avatars/reviews/ezengwa.jpeg';
import foluso from '../../../assets/img/avatars/reviews/foluso.jpeg';
import rumena from '../../../assets/img/avatars/reviews/rumena.jpeg';
import jahpee from '../../../assets/img/avatars/reviews/jahpee.jpeg';
import olanike from '../../../assets/img/avatars/reviews/olanike.jpeg';
import portia from '../../../assets/img/avatars/reviews/portia.jpeg';

const Reviews = () => {
    const reviews = [
        {
            image: foluso,
            track: 'Product Design',
            mentee: 'Elizabeth Folorunso',
            band: '2',
            review: 'For anyone looking to go into tech, Codevixens Academy will help lay a strong foundation that can be continuously built on.',
        },
        {
            image: olanike,
            track: 'Content Marketing',
            mentee: 'Oladejo Olanike',
            band: '2',
            review: 'My experience on Content Writing has been awesome and enlighting. You can also learn as I have and even more at Codevixens.',
        },
        {
            image: 'https://res.cloudinary.com/dar3e312jder/image/upload/v1718930237/website/unnamed_u3vdfe.png',
            track: 'HTML & CSS',
            mentee: 'Ruky Yakub',
            band: '2',
            review: 'So far I can tell you that Codevixens rocks. The program makes learning easy and fun.',
        },
        {
            image: 'https://res.cloudinary.com/dar3e312jder/image/upload/v1718930237/website/unnamed_u3vdfe.png',
            track: 'Content Marketing',
            mentee: 'Jahpee Bello',
            band: '2',
            review: 'Thanks, Codevixens Academy, for giving me the opportunity to become a better content marketer.',
        },
        {
            image: ezengwa,
            track: 'Product Design',
            mentee: 'Comfort Ezengwa',
            band: '2',
            review: 'Codevixens has made me appreciate UI/UX. I can identify websites that are not obeying the principles of UX and know how make it better for users.',
        },
        {
            image: portia,
            track: 'JavaScript',
            mentee: 'Portia Adaletey',
            band: '2',
            review: 'Codevixens Academy so far has been amazing. Having general meetings to get with the leaders to get everyone interactive is just so cool.',
        },
    ];

    useEffect(() => {
        if (window.theme && window.theme.swiperSlider()) {
            window.theme.swiperSlider();
        }
    }, []);
    return (
        <>
            <section id="snippet-10" className="wrapper bg-light">
                <div className="container pt-10 pt-md-10 pb-0 mt-10">
                    <div className="col-lg-7">
                        <h2 className="fs-16 text-uppercase text-muted mb-1">Why Study With Us?</h2>
                        <h2 className="display-5 ls-xs mb-2">Hear what our graduates are saying</h2>
                        <p>
                        Go through the firsthand experiences and success stories from our students in their journey with Codevixens.
                        These testimonials showcase the real-world impact of our programs and the success stories of our students.
                        </p>
                    </div>
                    <div className="position-relative">
                        <div className="card bg-none">
                            <div className="row gx-0">
                                <div className="col-lg-6">
                                    <div className="player rounded-0" data-plyr-provider="vimeo" data-plyr-embed-id="939326999" data-plyr-config='{ "title": "Example Title" }'></div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="px-10 px-md-11 px-lg-13 d-flex h-100 align-items-center bg-white shadow-lg ms-lg-3 mt-4 mt-lg-0">
                                        <blockquote className="icon icon-top fs-lg text-center">
                                            <p className='fs-15'>
                                                I would definitely recommend Codevixens for any woman looking to upskill in her tech career. 
                                                Apart from the intensive training, you get to attend session with experts, game nights in the community which is usually fun. 
                                                You also get to connect with your fellow techies as well and form meaningful relationships. <span className='d-block my-1'></span>
                                                Thank you Codevixens! 💜
                                            </p>
                                            <div className="blockquote-details justify-content-center text-center">
                                                <div className="info ps-0">
                                                    <h5 className="mb-0 fs-16">Mutiat Bashua</h5>
                                                    <p className="mb-0 fs-15">Alumni of our Reactjs Track - TB3.0</p>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="snippet-1" className="wrapper bg-light pt-8 mb-15">
                <div className="container">
                    <div className='grid'>
                        <div className="row isotope gy-6">
                            {reviews.map(({ band, review, mentee, track, image }, index) => (
                                <div className="item col-md-6 col-xl-4" key={index}>
                                    <div className="card">
                                        <div className="card-body px-5 py-4">
                                            <blockquote className="icon mb-0">
                                                <p className='mb-3 fs-15'>{`“${review}”`}</p>
                                                <div className="blockquote-details">
                                                    <img className="rounded-circle w-8" src={image} alt={mentee} />
                                                    <div className="info">
                                                        <h5 className="mb-0 fs-15">{mentee}</h5>
                                                        <p className="mb-0 fs-14">{`${track} Band ${band}.0`}</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Reviews;
