import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './assets/scss/style.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import Team from './components/pages/Team';
import AboutUs from './components/pages/About';
import Home from './components/pages/Home';
import Support from './components/Support';
import ErrorPage from './components/ErrorPage';
import ScrollToHashElement from './components/ScrollToHashElement';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Community from './components/pages/Community';
import Fellowship from "./components/pages/Fellowship";
import Testimonials from "./components/pages/Testimonials";
import Events from './components/pages/Events';
import AdmissionPolicy from './components/pages/admissions/Policy'
import AdmissionProcess from './components/pages/admissions/Process';
import FAQs from './components/pages/Faqs';
import Academy from './components/pages/Trainings';
import School from './components/pages/School';
import FinancialAssistance from './components/pages/about/FinancialAssistance';

function setExternalScripts(url) {
    url.forEach(function (externalScript, key) {
        const script = document.createElement('script');
        script.src = externalScript['url'];
        externalScript['attribute'] === 'async'
            ? (script.async = true)
            : (script.defer = true);
        document.body.appendChild(script);
    });
}

function App() {
    useEffect(() => {
        const onPageLoad = () => {
            setExternalScripts([
                { url: '/assets/js/theme.js', attribute: 'defer' },
            ]);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <>
            <div className='content-wrapper'>
                <Header />
                <ScrollToHashElement />
                <Routes>
                    <Route path='/' element={<Home />} />
                    {/* <Route path='/team' element={<Team />} /> */}
                    {/* <Route path='/fellowship' element={<Fellowship />} /> */}
                    {/* <Route path='/testimonials' element={<Testimonials />} /> */}
                    <Route path='/financial-assistance' element={<FinancialAssistance />} />
                    <Route path='/admissions-policy' element={<AdmissionPolicy />} />
                    <Route path='/admissions-process' element={<AdmissionProcess />} />
                    <Route path='/about-us' element={<AboutUs />} />
                    {/* <Route path='/faqs' element={<FAQs />} /> */}
                    {/* <Route path='/academy-program' element={<Academy />} /> */}
                    {/* <Route path='/academy-program/school' element={<School />} /> */}
                    {/* <Route path='/community' element={<Community />} /> */}
                    {/* <Route path='/events' element={<Events />} /> */}
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
            </div>
            <Support />
            <Footer />
            {setExternalScripts([
                { url: '/assets/js/plugins.js', attribute: 'defer' },
            ])}
        </>
    );
}

export default App;
