import React from 'react';
import '../assets/css/plugins.css';
import './support.css';
import Logo from '../assets/img/logo.svg';
import '../assets/css/plugins.css';
// import { Link } from 'react-router-dom';

const Support = () => {
    return (
        <>
            <button
                id='support'
                data-bs-toggle='modal'
                data-bs-target='#modal-02'
                data-bs-backdrop={false}
                className='animation-default animation-delay-1500 btn btn-lg bg-dark position-fixed fw-normal text-white rounded btn-outline-dark p-sm-2 p-1'>
                <span className='ms-sm-2 ms-1 ls-md fs-14'>
                <p className="mb-0 fw-bold">Have Any Questions?</p>
                <p className="mb-0">Say Hi! We are available to help.</p>
                </span>
                <span className='badge bg-white p-1 rounded-circle ms-3'>
                    <i className='uil uil-question fs-20 text-dark'></i>
                </span>
            </button>

            {/* We can use this when the contact API is ready to use */}
            {/* <div className='modal fade' id='modal-02' tabIndex='-1'>
                <div className='modal-dialog  modal-dialog-centered modal-md'>
                    <div className='modal-content bg-gradient text-center'>
                        <div className='modal-header px-sm-10 px-5 py-3 align-items-center border-bottom border-secondary border-1'>
                            <img className='img-fluid w-12' src={Logo} alt='' />
                            <button
                                className='opacity-100 btn-close text-white bg-primary rounded-circle p-0 position-relative top-0 w-auto h-auto me-1'
                                data-bs-dismiss='modal'
                                aria-label='Close'></button>
                        </div>
                        <div className='modal-body text-center px-sm-10 px-5'>
                            <h4>We're always happy to help</h4>
                            <p>
                                If you have any questions, you can reach out to
                                us through our contact form. We will reply to
                                all messages.
                            </p>
                            <Link to='/contact'>
                                <button
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                    className='btn btn-primary rounded-pill btn-lg'>
                                    Contact Us
                                </button>
                            </Link>
                            <p className='mb-0 mt-3'>
                                You can also contact us at{' '}
                                <a
                                    href='mailto:programs@codevixens.org'
                                    className='text-primary'>
                                    programs@codevixens.org
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Contact by email and social media handles */}
            <div className='modal fade' id='modal-02' tabIndex='-1'>
                <div className='modal-dialog  modal-dialog-centered modal-md'>
                    <div className='modal-content bg-gradient text-center'>
                        <div className='modal-body text-center px-sm-10 px-5'>
                            <h4>We&apos;re always happy to help</h4>
                            <p className='mb-2'>
                                If you have any questions, you can reach out to
                                us through our email address. We will reply to
                                all messages.
                            </p>
                            <a
                                href='mailto:programs@codevixens.org'
                                className='text-navy fw-bold'>
                                programs@codevixens.org
                            </a>
                            <p className='mb-0 mt-2'>
                                You can also contact us with our social media
                                handles
                            </p>
                            <nav className='social'>
                                <a
                                    href='https://twitter.com/codevixens'
                                    target='_blank'
                                    rel='noreferrer'>
                                    <i className='uil uil-twitter'></i>
                                </a>
                                <a
                                    href='https://www.facebook.com/people/Codevixens-Academy/100088290937780'
                                    target='_blank'
                                    rel='noreferrer'>
                                    <i className='uil uil-facebook-f'></i>
                                </a>
                                <a
                                    href='https://www.linkedin.com/company/codevixens-academy'
                                    target='_blank'
                                    rel='noreferrer'>
                                    <i className='uil uil-linkedin'></i>
                                </a>
                                <a
                                    href='https://www.instagram.com/codevixenshq'
                                    target='_blank'
                                    rel='noreferrer'>
                                    <i className='uil uil-instagram'></i>
                                </a>
                                <a
                                    href='https://www.youtube.com/@codevixensacademy'
                                    target='_blank'
                                    rel='noreferrer'>
                                    <i className='uil uil-youtube'></i>
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Support;
