import WhoWeAre from './about/WhoWeAre';
import '../../assets/css/plugins.css'

const AboutUs = () => {
    return (
        <>
            <WhoWeAre />
        </>
    );
}

export default AboutUs;