import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/img/logo.svg';
const instructorApplicationUrl = 'https://forms.gle/Edd2Jun1XmT4jzzc9';
const registrationUrl = 'https://tr.ee/CDV24BA';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <>
            {' '}
            <footer className="bg-dark text-inverse">
                <div className="container py-13 py-md-10">
                    <div className="row gy-6 gy-lg-0">
                        <div className="col-md-4 col-lg-3">
                            <div className="widget">
                                <img className="mb-4 img-fluid w-15" src={Logo} alt="" />
                                <p className="mb-4">
                                    © {currentYear} Codevixens{' '}
                                    <br className="d-none d-lg-block" />
                                    All rights reserved.
                                </p>
                                <nav className="nav social social-white">
                                    <a href="https://twitter.com/codevixens">
                                        <i className="uil uil-twitter"></i>
                                    </a>
                                    <a href="https://www.facebook.com/people/Codevixens-Academy/100088290937780">
                                        <i className="uil uil-facebook-f"></i>
                                    </a>
                                    <a href="https://www.linkedin.com/company/codevixens-academy">
                                        <i className="uil uil-linkedin"></i>
                                    </a>
                                    <a href="https://www.instagram.com/codevixenshq">
                                        <i className="uil uil-instagram"></i>
                                    </a>
                                    <a href="https://www.youtube.com/@codevixensacademy">
                                        <i className="uil uil-youtube"></i>
                                    </a>
                                </nav>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-2">
                            <div className="widget">
                                <h4 className="widget-title text-white mb-3">
                                    Enrol in our program
                                </h4>
                                <ul className="list-unstyled mb-0">
                                    <li><Link target={'_blank'} to={registrationUrl}>CAS 2024 - Band A</Link></li>
                                    <li><Link target={'_blank'} to={instructorApplicationUrl}>As an instructor</Link></li>
                                    <li><Link to="https://tr.ee/ZlRzxkwQfH">Join our online community</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-3">
                            <div className="widget">
                                <h4 className="widget-title text-white mb-3">Our Educational Programs</h4>
                                <ul className="list-unstyled mb-0">
                                    {/* <li><Link to="/team">Codevixens Internship</Link></li> */}
                                    <li><Link to="/#codevixens-fellowship">Codevixens Fellowship</Link></li>
                                    <li><Link to="/academy-program">Codevixens Academy</Link></li>
                                    <li><Link to="/#codevixens-20d-challenge">20 Days Challenge</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-2">
                            <div className="widget">
                                <h4 className="widget-title text-white mb-3">Resources</h4>
                                <ul className="list-unstyled mb-0">
                                    <li><Link to="https://medium.com/the-codevixens-blog">Blog Posts</Link></li>
                                    <li><Link target={'_blank'} to="https://www.youtube.com/@codevixensacademy">Videos</Link></li>
                                    {/* <li><Link to="/community">Testimonials</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-2">
                            <div className="widget">
                                <h4 className="widget-title text-white mb-3">Learn More</h4>
                                <ul className="list-unstyled mb-0">
                                    <li><Link to="/admissions-process#admissions-process">Admission Process</Link></li>
                                    <li><Link to="/admissions-policy#admissions-policy">Admission Policy</Link></li>
                                    <li><Link to="/about-us#snippet-13">About Codevixens</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
