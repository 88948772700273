import React, { useState, useEffect } from 'react';
import Hero from './home/Hero';
// import '../../assets/css/plugins.css';
// import './home/home.css';
import Reviews from './home/Reviews';
import OurPartners from './home/OurPartners';
// import WhoWeAre from './home/WhoWeAre';
import OurInitiatives from './home/OurInitiatives';
// import Partners from './home/Partners';
// import JoinUs from "./home/JoinUs";
import Faqs from "./home/Faqs";
import AnnouncementPopup from '../modals/AnnouncementPopup';

const Home = () => {
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);

    // useEffect(() => {
    //     // Show the modal when the component is mounted
    //     setShowModal(true);
    // }, []);
    return (
        <div id='homepage'>
            {/* <AnnouncementPopup show={showModal} handleClose={handleClose} /> */}
            <Hero />
            <OurPartners />
            <OurInitiatives />
            <Reviews />
            <Faqs />
            {/* <JoinUs /> */}
        </div>
    );
};

export default Home;
